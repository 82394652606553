
import NotFoundLayout from '@/layouts/NotFoundLayout.vue'
import { Vue, Component } from 'vue-property-decorator'
import TheMenuSidebar from '@/components/TheMenuSidebar.vue'
import TheAppBar from '@/components/TheAppBar.vue'

@Component({
  components: {
    NotFoundLayout,
    TheMenuSidebar,
    TheAppBar,
  },
})
export default class NotFound extends Vue {}
